import React from "react";
import slogan_any from "../../assets/img/slogan_any.png";
import slogan_backgr from "../../assets/img/slogan_backgr.png";
import slogan_detection from "../../assets/img/slogan_detection.png";
import slogan_human from "../../assets/img/slogan_human.png";
import slogan_sign from "../../assets/img/slogan_sign.png";
import slogan_ticket from "../../assets/img/slogan_ticket.png";
import "./slogan.scss";

const slogansData = [
  {
    icon: slogan_any,
    text: "Intelligent all-around traffic monitoring with just any smartphone.",
  },
  {
    icon: slogan_backgr,
    text: "Easy on resources, working in the background with any other app on your smartphone.",
  },
  {
    icon: slogan_human,
    text: "Completely autonomic in real-time, no driver attention is needed.",
  },
  {
    icon: slogan_sign,
    text: "Detects up to 50 traffic violation events.",
  },
  {
    icon: slogan_ticket,
    text: "Produces scientifically sound data for dispute resolution.",
  },
  {
    icon: slogan_detection,
    text: "Determines the speed of adjacent vehicles with up to 5 km/h error margin, measures the distance between vehicles and any other road objects with 0.1 m accuracy.",
  },
];

const Slogan = () => {
  return (
    <section>
      <hr />
      <div id="slogans" className="slogans">
        {slogansData.map((slogan, index) => (
          <div key={index} className="slogan">
            <ul className="slogan_row">
              <li className="slogan_inner">
                <div className="slogan_element">
                  <img src={slogan.icon} className="slogan_icon" alt="icon" />
                  <p>{slogan.text}</p>
                </div>
              </li>
            </ul>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Slogan;
