import { useEffect, useRef, useState } from "react";
import arrowLeft from "../../assets/img/arrow_left.png";
import arrowRight from "../../assets/img/arrow_right.png";
import video1 from "../../assets/video/highway.mp4";
import video4 from "../../assets/video/offencive.mp4";
import video3 from "../../assets/video/tonel.mp4";
import video2 from "../../assets/video/yield.mp4";

import "./home.scss";

const Home = () => {
  const videos = [video1, video2, video3, video4];

  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const videoRef = useRef(null);

  const playNextVideo = () => {
    setCurrentVideoIndex((prevIndex) => (prevIndex + 1) % videos.length);
  };

  const playPreviousVideo = () => {
    setCurrentVideoIndex(
      (prevIndex) => (prevIndex - 1 + videos.length) % videos.length
    );
  };

  const handleVideoEnded = () => {
    playNextVideo();
  };

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.src = videos[currentVideoIndex];
    }
  }, [currentVideoIndex]);

  return (
    <section>
      <div className="phoneContent">
        <button id="back" className="btnSlider" onClick={playPreviousVideo}>
          <img src={arrowLeft} alt="arrow left" />
        </button>
        <div className="video">
          <div>
            <video
              autoPlay
              muted
              playsInline
              ref={videoRef}
              onEnded={handleVideoEnded}
            />
          </div>
        </div>
        <button id="next" className="btnSlider" onClick={playNextVideo}>
          <img src={arrowRight} alt="arrow right" />
        </button>
      </div>
    </section>
  );
};

export default Home;
