import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/img/logo.png";
import "./header.scss";

const Header = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const menuOptions = [
    { label: "about", path: "/about" },
    { label: "technology", path: "/technology" },
    { label: "smart photogrammetry", path: "/blog" },
    { label: "contacts", path: "/contacts" },
  ];

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <div className="menu">
      <Link to="/">
        <img src={logo} className="menu_logo" alt="logo" />
      </Link>
      <button className="hamburger" onClick={toggleMobileMenu}>
        ☰
      </button>
      <ul className={`menu_regular ${isMobileMenuOpen ? "menu_open" : ""}`}>
        {menuOptions.map((option) => (
          <li className="menu_element" key={option.label}>
            <Link to={option.path} className="refference">
              {option.label.toUpperCase()}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Header;
