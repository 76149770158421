import React from "react";
import { Route, Routes } from "react-router-dom";
import "./App.scss";
import About from "./components/about/About";
import Blog from "./components/blog/Blog";
import Contacts from "./components/contacts/Contacts";
import Footer from "./components/footer/Footer";
import Header from "./components/header/Header";
import Home from "./components/home/Home";
import Slogan from "./components/slogan/Slogan";
import Technology from "./components/technology/Technology";

const App = () => {
  return (
    <div id="app">
      <Header />
      <main>
        <Routes>
          <Route
            path="/"
            element={
              <div>
                <Home />
                <Slogan />
                <About />
                <Technology />
                <Contacts />
              </div>
            }
          />
          <Route path="/about" element={<About />} />
          <Route path="/technology" element={<Technology />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/contacts" element={<Contacts />} />
        </Routes>
      </main>
      <Footer />
    </div>
  );
};

export default App;
