import React from "react";
import MathJax from "react-mathjax";

import article_1 from "../../assets/img/article1.jpg";
import article_2 from "../../assets/img/article2.jpg";
import article_3 from "../../assets/img/article3.jpg";
import article_4 from "../../assets/img/article4.jpg";
import article_5 from "../../assets/img/article5.jpg";
import article_6 from "../../assets/img/article6.jpg";
import article_7 from "../../assets/img/article7.jpg";
import article_8 from "../../assets/img/article8.jpg";

import article_image from "../../assets/img/article_img.jpg";

import "./blog.scss";

const Blog = () => {
  return (
    <section id="blog" className="blog">
      <h2 className="section_heading">smart photogrammetry</h2>
      <hr />
      <div className="container_blog">
        <div className="blog_element">
          <img
            src={article_image}
            alt="img"
            className="blog_element_article_image"
          />
          <div className="blog_element_text_intro">
            <p className="blog_element_intro">
              Dear fans of photogrammetry, remote sensing, computer vision, and
              artificial intelligence!
              <br /> With this article, I would like to open a series of
              publications under the general title
            </p>
            <p className="blog_element_intro_center">
              Smart Photogrammetry
            </p>{" "}
            <br />
            <p className="blog_element_intro">
              The forced silence, which stretched for 6 years, ended. I intend
              to return to my usual rate of 2-3 articles per month! Moreover, a
              lot of topics for discussion have accumulated. I urge everyone who
              has something to say to join. Publications and kind criticism are
              encouraged.
            </p>{" "}
            <br />
            <p>
              Yours sincerely,
              <br />
              Dr. Evgeny Medvedev
              <br /> Haifa, the Mediterranean coast
            </p>
          </div>
        </div>
        <div className="blog_element_article">
          <h2 className="section_heading">
            Story #1 Artificial Intelligence and a Bike{" "}
          </h2>
          <hr />
        </div>

        {/* article */}

        <div className="main-container">
          <p>
            What can a single shot of a bicycle, Fig.1, say from a
            photogrammetric point of view? Nothing! Traditional photogrammetry
            needs to have at least a couple of overlapping images, a calibrated
            camera, a GPS, an inertial system… Then it might say something.
          </p>
          <br />
          <div className="figure articleImage">
            <img src={article_1} alt="capture" className="img1" />
            <p className="caption">Figure 1</p>
          </div>
          <br />
          <p>
            What can a neural network say from a single snapshot of a bicycle?
            Some useful stuff! The neural network (AI, Deep Learning) can infer
            that the picture shows a bike. It detects the presence of a front
            and rear wheel. It performs image segmentation by selecting
            fragments of the image that geometrically correspond to a particular
            object. Fig.2 shows the results of recognition and segmentation
            using the popular YOLO900 platform.
          </p>
          <br />
          <div className="figure articleImage">
            <img src={article_2} alt="capture" className="img2" />
            <p className="caption">Figure 2</p>
          </div>
          <br />
          <p>
            What can a complex of neural network and an advanced photogrammetric
            procedure do with a single image of a bicycle? A whole darn lot! The
            combination of these techniques, aka smart photogrammetry, can
            calculate distances, areas, volumes, speeds, and accelerations,
            provide quantitative estimates for the probability of collision of
            moving objects, establish the similarities and differences of
            objects, calibrate video cameras, and so much more. Smart
            photogrammetry can do all this with very modest processing power and
            commonplace photographic devices. A regular smartphone camera is
            fine. Of course, if it is possible to take more than one snapshot
            but that will be just nice to have, not a necessity. More shots give
            richer results and the better precision. However, let’s start with a
            simpler situation for this article and consider a bicycle on a
            single shot.
          </p>
          <hr />
          <h3>
            Proof of concept: Complete spatial orientation based on a single
            image
          </h3>
          <p>
            Let’s show why a single shot is enough to perform a complete spatial
            orientation of our bicycle object model. In Fig.3 we introduce a
            coordinate system for the bicycle.
          </p>
          <br />
          <div className="figure articleImage">
            <img src={article_3} alt="capture" className="img3" />
            <p className="caption">Figure 3</p>
          </div>
          <br />
          <p>
            Fig.4 shows a photogrammetric observation scheme. The real bicycle
            wheel is shown in brown on the right. Nothing is known about its
            position or orientation. The image of the wheel (in the image
            coordinate system) is shown in blue. This is the result of AI work.
            This is known data.
          </p>
          <br />
          <div className="figure articleImage">
            <img src={article_4} alt="capture" className="img4" />
            <p className="caption">Figure 4</p>
          </div>
          <br />
          <MathJax.Provider>
            <div className="article">
              <p>
                In Fig.4 the following designations are adopted:
                <br />
                <br />
                <span className="math inline formula bold">oXYZ</span> – camera
                coordinate system.
                <br />
                <span className="math inline formula">S</span> – sensor plane.
                <br />
                <span className="math inline formula">P</span> – the principal
                point
                <br />
                <span className="math inline formula">M</span> – the main point
                <br />
                <span className="math inline formula">Xc, Yc</span> –
                approximate center of the wheel image.
                <br />
                We will assume that the focal length is known and equal to{" "}
                <span className="math inline formula">F</span>
              </p>

              <div className="center">
                <MathJax.Node inline formula={"|PM|=F"} />
              </div>

              <p>
                The vector <MathJax.Node inline formula={"R_c"} />. going from{" "}
                <span className="math inline formula">P</span> to the physical
                center of the wheel is expressed as in{" "}
                <span className="math inline formula bold">oXYZ</span>:
              </p>

              <MathJax.Node
                formula={`\\pmb{R_c} = \\left(\\frac{X_c}{L}, \\frac{Y_c}{L}, \\frac{Z_c}{L}\\right)`}
              />

              <MathJax.Node
                formula={`L = \\sqrt{{X_c}^2 + {Y_c}^2 + {Z_c}^2}`}
              />

              <MathJax.Node formula={`\\left|\\pmb{R_c}\\right| = 1`} />

              <p>
                Let us introduce an auxiliary plane{" "}
                <span className="math inline formula">T</span>, passing through
                the point &#40;
                <MathJax.Node inline formula={"X_c"} />,{" "}
                <MathJax.Node inline formula={"Y_c"} />,{" "}
                <MathJax.Node inline formula={"F"} /> &#41; and perpendicular to{" "}
                <MathJax.Node inline formula={"R_c"} />. The equation of this
                plane in <span className="math inline formula bold"> oXYZ</span>{" "}
                looks as follows:
              </p>

              <MathJax.Node
                formula={`\\frac{X_c}{L}(X-X_c) + \\frac{Y_c}{L}(Y-Y_c) + \\frac{Z_c}{L}(Z-Z_c) = 0`}
              />
            </div>
            <br />{" "}
            <p>
              We now select a point &#40;
              <MathJax.Node inline formula={"X_c"} />,{" "}
              <MathJax.Node inline formula={"Y_c"} />,{" "}
              <MathJax.Node inline formula={"F"} /> &#41; as the origin and set
              an arbitrary rectangular coordinate system. A one-to-one
              correspondence can be established between the wheel images in{" "}
              <span className="math inline formula">S</span> and{" "}
              <span className="math inline formula">T</span> (Fig.5). The
              projection ray <span className="math inline formula">r</span>{" "}
              passing through the point{" "}
              <span className="math inline formula">(x, y)</span> in{" "}
              <span className="math inline formula">S</span> intersects{" "}
              <span className="math inline formula">T</span> at some point &#40;
              <MathJax.Node inline formula={"x', y'"} />
              &#41;. These coordinates can be determined using the following
              equation:
            </p>
            <br />
            <MathJax.Node
              formula={`\\frac{X_c}{L}\\left(\\lambda \\frac{X}{{L}^*} - X_c\\right) + \\frac{Y_c}{L}\\left(\\lambda \\frac{Y}{{L}^*} - Y_c\\right) + \\frac{Z_c}{L}\\left(\\lambda \\frac{Z}{{L}^*} - Z_c\\right) = 0`}
            />
            <p style={{ textAlign: "center" }}>
              <span style={{ fontStyle: "italic" }}>(1)</span>
            </p>
            <p>
              where -
              <br />
              <MathJax.Node formula={`{L}^* = \\sqrt{X^2 + Y^2 + Z^2}`} />
              This equation is always solved for{" "}
              <MathJax.Node inline formula={`\\lambda`} />, which gives the
              coordinates of the intersection at <span className="bold">T</span>{" "}
              at <span className="bold"> oXYZ</span>:
              <br />
              <MathJax.Node formula={`X^* = \\lambda_0 \\frac{X}{{L}^*}`} />
              <MathJax.Node formula={`Y^* = \\lambda_0 \\frac{Y}{{L}^*}`} />
              <MathJax.Node formula={`Z^* = \\lambda_0 \\frac{Z}{{L}^*}`} />
              where <MathJax.Node inline formula={`\\lambda_0`} /> is the
              solution of Eq. <a href="#eq:proj-coord">(1)</a>. Coordinates{" "}
              &#40; <MathJax.Node inline formula={`\X_t`} />,{" "}
              <MathJax.Node inline formula={`\Y_t`} />
              &#41; can be defined as the following cross product:
              <MathJax.Node formula={`\{X_t} = \{V} \\times \{X_t}`} />
              <MathJax.Node formula={`\{Y_t} = \{V} \\times \{Y_t}`} />
            </p>
            <p>
              where{" "}
              <MathJax.Node
                inline
                formula={` \V = (X^* - X_c, Y^* - Y_c, Z^* - F)`}
              />
              and <MathJax.Node inline formula={`\X_t`} />,{" "}
              <MathJax.Node inline formula={`\Y_t`} />
              are the basis vectors of <span className="bold"> oXYZ</span>. The
              inverse transformation can be performed similarly.
            </p>
            <div className="figure articleImage">
              <img src={article_5} alt="capture" className="img5" />
              <p className="caption">Figure 5</p>
            </div>
            <br />
            <p>
              The curve <span className="math inline formula">Φ</span> in the
              first approximation can be considered an ellipse. Its semi-major
              axis a in the plane <span className="bold">T</span> is parallel to
              the plane of the wheel in the object coordinate system (Fig.6).
            </p>
            <br />
            <div className="figure articleImage">
              <img src={article_6} alt="capture" className="img6" />
              <p className="caption">Figure 6</p>
            </div>
            <p>
              This allows to determine the geo-location of the object coordinate
              system in relation to the camera coordinate system.
            </p>
            <MathJax.Node formula={`{A}|| \{a}`} />
            <MathJax.Node
              formula={`\\alpha = \\angle (\\pmb{B}, \\pmb{b}) = \\arccos\\left(\\frac{\\pmb{b}}{\\pmb{a}}\\right)`}
            />
            <MathJax.Node
              className="inline formula"
              formula={`\\pmb{aa} \\perp \\pmb{bb}, \\pmb{AA} \\perp \\pmb{BB}`}
            />
            <br />
            <p>
              Note that the vector produced by{" "}
              <span className="bold">A x B</span> coincides in direction with
              the{" "}
              <MathJax.Node
                inline
                className="inline formula"
                formula={`X ^ b`}
              />{" "}
              axis of the object. The angle between the plane{" "}
              <span className="bold">T</span> and the plane of the wheel is{" "}
              <MathJax.Node
                className="inline formula"
                inline
                formula={`\\alpha`}
              />
              , and the line of their intersection is parallel to a. Thus, the
              relative position of the plane of the wheel and{" "}
              <span className="bold">T</span> can be considered determined with
              an accuracy to scale. Since the mutual position of{" "}
              <span className="bold">T</span> and{" "}
              <span className="bold">S</span> is well defined, we can assume
              that{" "}
              <MathJax.Node
                className="inline formula"
                inline
                formula={`X ^ b`}
              />{" "}
              is well defined in <span className="bold"> oXYZ</span>:
            </p>
            <MathJax.Node
              className="inline formula"
              formula={`{X ^ b} = ({X_X}^b, {X_Y}^b, {X_Z}^b)`}
            />
            <p>
              Note that the projection of the{" "}
              <MathJax.Node
                inline
                className="inline formula"
                formula={`X ^ b`}
              />{" "}
              axis in the <span className="bold">SC</span> of the image
              coincides with the lower tangent to both wheels, which immediately
              gives us the vector{" "}
              <MathJax.Node
                inline
                className="inline formula"
                formula={`Y ^ b`}
              />{" "}
              in <span className="bold"> oXYZ</span>. The vector{" "}
              <MathJax.Node
                inline
                className="inline formula"
                formula={`Z ^ b`}
              />{" "}
              can be obtained as the cross product of{" "}
              <MathJax.Node
                inline
                className="inline formula bold"
                formula={`X ^ b`}
              />{" "}
              and{" "}
              <MathJax.Node
                inline
                className="inline formula bold"
                formula={`Y ^ b`}
              />
              .
            </p>
            <p>
              {" "}
              Thus, we have performed the spatial orientation of the object
              coordinate system relative to the camera coordinate system. For
              any point <span className="bold">D</span> on the image, we can
              represent the mutually proportional projections of the axes of the
              object’s coordinate system (Fig.7). The exact scale remains
              unknown. However, thanks to our AI data, this object is recognized
              as a bicycle and we can use a scale estimate based on typical
              dimensions of a bicycle.
            </p>
            <br />
            <div className="figure articleImage">
              <img src={article_7} alt="capture" className="img7" />
              <p className="caption">Figure 7</p>
            </div>
            <hr />
            <p>
              We have shown how easily the most important photogrammetric
              problem of geo-location of an object can be solved when AI and
              photogrammetric methods are used together. The simplest case is
              used, which, at first glance, has no special practical
              significance.
            </p>
            <div className="figure articleImage">
              <img src={article_8} alt="capture" className="img8" />
              <p className="caption">Figure 8</p>
            </div>
            <p>
              However, this is done on purpose, so that in the following
              articles we can move on to discussing more realistic cases that
              are of great practical importance. For now, let’s list the
              simplifications used in this article:
              <br /> 1. The focal length, interior orientation elements are
              considered known, and the image geometry strictly obeys the laws
              of central projection. In the practice of ICOP Engineering, this
              is not at all the case. Household uncalibrated cameras of
              smartphones with variable focal length are used.
              <br /> 2. Image segmentation, performed by the YOLO9000 network,
              gives the image of wheels in the form of regular geometric shapes.
              In practice, this is far from the case. The segments formed by the
              network are very noisy. Non-trivial efforts are required to pass
              to a regular geometric figure of type{" "}
              <span className="bold">F</span> in Fig.6.
              <br /> 3. Finally, a bicycle is the simplest object of observation
              due to its almost complete two-dimensionality (its width in{" "}
              <MathJax.Node
                inline
                className="inline formula bold"
                formula={`X ^ b`}
              />
              can be neglected)
            </p>
            <p>
              In subsequent articles, we will look at smart photogrammetry
              applications for more complex objects than a bicycle. Let’s start
              with a motorbike (Fig.8). Already with the case of a motorbike,
              much more can be achieved in a practical sense than we have
              achieved with a bike.
            </p>
          </MathJax.Provider>
        </div>
      </div>
    </section>
  );
};

export default Blog;
