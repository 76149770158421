import React from "react";
import { Link } from "react-router-dom";
import logowhite from "../../assets/img/logowhite.png";
import "./footer.scss";

const Footer = () => {
  const menuOptions = [
    { label: "about", path: "/about" },
    { label: "technology", path: "/technology" },
    { label: "smart photogrammetry", path: "/blog" },
    { label: "contacts", path: "/contacts" },
  ];

  return (
    <div className="footer">
      <hr />
      <ul className="footer_regular">
        <li className="menu_element">
          <Link to="/" className="footer_element">
            <img src={logowhite} className="menu_logo" alt="logo" />
          </Link>
        </li>
        {menuOptions.map((option) => (
          <li className="menu_element" key={option.label}>
            <Link to={option.path} className="footer_element hidden_on_small">
              {option.label.toUpperCase()}
            </Link>
          </li>
        ))}
        <li>
          <a class="footer_element" href="mailto:info@icop-systems.com">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="36"
              height="36"
              fill="#e19240"
              class="bi bi-envelope-fill"
              viewBox="0 0 16 16"
            >
              <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555zM0 4.697v7.104l5.803-3.558L0 4.697zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757zm3.436-.586L16 11.801V4.697l-5.803 3.546z" />
            </svg>
          </a>
        </li>
      </ul>
    </div>
  );
};

export default Footer;
