import "./contacts.scss";

const Contacts = () => {
  return (
    <section>
      <h2 className="section_heading">Contact Us</h2>
      <hr />
      <div className="contact_and_map">
        <div className="map_info">
          <iframe
            className="map"
            title="Google Map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d53956.24593695246!2d34.82536849671146!3d32.30472361584737!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x151d400493c075d5%3A0x2cd995be543c3f22!2sNetanya%2C%20Israel!5e0!3m2!1sen!2sca!4v1635561008874!5m2!1sen!2sca"
            style={{ border: "0" }}
            allowFullScreen=""
            loading="lazy"
          ></iframe>
        </div>

        <div className="contacting_info">
          <form action="https://formspree.io/f/mbjqbgao" method="POST">
            <div className="contact_form">
              <p className="contact_form_text"> Your email:</p>
              <input type="email" name="_replyto" />

              <p className="contact_form_text">Your message:</p>
              <textarea name="message"></textarea>

              <button className="contact_form_btn" type="submit">
                Send
              </button>
            </div>
          </form>
        </div>
      </div>
      {/* <div className="pictures_container">
        <img
          className="pictures_container_image_sign"
          src={companylogo}
          alt=""
        />
        <img className="pictures_container_image" src={img2} alt="" />

        <img className="pictures_container_image" src={img1} alt="" />
        <img
          className="pictures_container_image_sign"
          src={companyimg}
          alt=""
        />
      </div> */}
    </section>
  );
};

export default Contacts;
