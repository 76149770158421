import React from "react";
import accel from "../../assets/img/accel.png";
import montreal from "../../assets/img/montreal.png";
import tickmark from "../../assets/img/tickmark.png";
import "./technology.scss";

const Technology = () => {
  const advantages = [
    {
      text: "ICOP technology assumes complete autonomy. Any modern smartphone (running Android or iOS) with a conventional video camera is supported. Exotic technologies such as Smart Light and Lidar are not used.",
    },
    {
      text: "No special smartphone mount is necessary. The only requirement is to ensure visibility of the road (a clear line of sight between the phone camera and the road). No special procedures or calibrations are required from the user. Driving related vibration is acceptable and does not significantly affect the performance of the app.",
    },
    {
      text: "ICOP takes care of smartphone resources. Low processor load allows you to use your smartphone in parallel for any other purpose, including your navigation application. Only the data of the detected violation is being stored.",
    },
  ];

  return (
    <section>
      <h2 className="section_heading">Technology</h2>
      <hr />
      <div className="container_technology_moreInfo">
        <div className="technology_side">
          <img className="technology_side_image" src={montreal} alt="img" />
          <img className="technology_side_image" src={accel} alt="img" />
        </div>
        <div className="technology_side">
          <ul className="technology_advantages">
            {advantages.map((advantage, index) => (
              <li key={index}>
                <img
                  className="technology_advantages_list_image"
                  src={tickmark}
                  alt="checkmark"
                />
                <div className="technoligy_element_text">
                  <div className="technology_bold">{advantage.text}</div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="technology_element_text_additional">
        <p className="technoligy_element_text">
          All identified violations are georeferenced, allowing to determine the
          speed limit, as well as the presence of road signs on a given section.
          The system detects sudden braking or sharp changes in driving
          direction and identifies the vehicle forcing you to take these evasive
          actions. Recognizes and takes into account the presence of road signs
          and traffic lights. Detects offensive gestures and flags
          environmentally hazardous vehicles giving off smoky exhaust.
        </p>
      </div>
    </section>
  );
};

export default Technology;
