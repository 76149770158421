import React from "react";
import sampleimg1_about from "../../assets/img/1.png";
import sampleimg2_about from "../../assets/img/2.png";
import companyimg from "../../assets/img/companyimg.png";
import companylogo from "../../assets/img/companylogo.png";
import sample2_about from "../../assets/img/sample2_about.png";

import "./about.scss";

const About = () => {
  return (
    <section>
      <h2 className="section_heading">About</h2>
      <hr />
      <div className="container">
        <div className="container_about">
          <img src={sample2_about} alt="img" className="about_image" />
          <div className="about_text">
            <h5 className="about_heading">
              An ordinary smartphone uses consumer grade technology producing
              less accurate information compared to that of specialized systems
              used in autonomous vehicles.
            </h5>
            <br />
            <p>
              These systems include high-resolution cameras, lidars, and other
              dedicated electronic devices. However, ICOP Engineering leverages
              cutting-edge artificial intelligence and computer vision
              technologies to overcome this limitation. Our mobile software
              application turns any smartphone into an effective means of
              quantitative traffic monitoring in real-time. With ICOP
              technologies, you can determine the speed of all vehicles around
              you. And not only speed! No volitation will go undetected. Anyone
              who does not yield when they should have, cuts you off or makes an
              offensive gesture will be flagged and incur a negative record.
            </p>
          </div>
        </div>
        <div class="pictures_container">
          <div class="pictures_container_side">
            <img
              class="pictures_container_image_sign"
              src={companylogo}
              alt=""
            />
            <img
              class="pictures_container_image"
              src={sampleimg2_about}
              alt=""
            />
          </div>
          <div class="pictures_container_side">
            <img
              class="pictures_container_image"
              src={sampleimg1_about}
              alt=""
            />
            <img
              class="pictures_container_image_sign"
              src={companyimg}
              alt=""
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
